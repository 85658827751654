<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './views/home/homeIndex.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
}
</style>
