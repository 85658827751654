<template>
  <div class="home" id="homeBox">
    <div class="navigation">
      <div class="navigationCen">
        <div class="logoBox"><img src="../../assets/logoTop.png" /></div>
        <ul>
          <li @click="roll('homeBox')">首页</li>
          <li @click="roll('schemeBox')">解决方案</li>
          <li @click="roll('successfulCaseBox')">成功案例</li>
          <li @click="roll('aboutUsBox')">公司介绍</li>
          <li @click="roll('contactUsBox')">联系我们</li>
        </ul>
      </div>
    </div>
    <div class="carouselBox">
      <img src="../../assets/homeImgTop.jpg" />
      <div class="carouselText">
        <img src="../../assets/homeTextTop.png" />
        <div class="relationButtonBack" @click="roll('contactUsBox')">
          <img src="../../assets/relationImg.png" />
          <span>联系我们</span>
        </div>
      </div>
    </div>
    <div class="cententBox">
      <div class="synopsis" id="schemeBox">
        <span><em>“</em>质量为先，信誉为重</span>
        <span><em>“</em>管理为本，服务为诚</span>
        <span><em>“</em>爱岗敬业，开拓进取</span>
        <span><em>“</em>创新服务，放眼未来</span>
      </div>
      <div class="title">解决方案</div>
      <div class="schemeBox">
        <ul>
          <li>
            <div class="text">
              <div class="textTitle">
                <img src="../../assets/schemeIcon1.png" />
                <span style="color: #2B6AFF">讯聘数字化劳务派遣管理系统</span>
              </div>
              <div class="textBox">
                讯聘数字化劳务派遣管理系统是基于云计算技术的人力资源管理平台，可实现劳务派遣企业的日常管理和劳务派遣流程自动化。通过平台，企业可以实现人员招聘、员工入职、工资结算、考勤管理、人事档案管理等多项功能，并提供数据分析和报表生成等服务，让企业轻松管理劳务派遣业务，提高管理效率和业务运营水平。
              </div>
            </div>
            <div class="backImg">
              <img src="../../assets/scheme1.jpg" />
            </div>
          </li>
          <li class="event">
            <div class="text">
              <div class="textTitle">
                <img src="../../assets/schemeIcon2.png" />
                <span style="color: #007FEE">医药行业数据安全解决方案</span>
              </div>
              <div class="textBox">
                医药行业数据安全解决方案助力用户完善内部数据安全管控体系，从而满足法律法规对单位信息化安全建设的要求；通过数据资产摸底数据分类分级，为敏感数据合理化管理提供了可靠依据；通过数据安全综治平台的引入，为用户构建统一联防联动，合成作战的综合防御体系；利用AI人工智能技术为用户提供全方位的可视化数据安全态势感知能力；整体方案涵盖全生命周期、全数据形态全流通环节防护。
              </div>
            </div>
            <div class="backImg">
              <img src="../../assets/scheme2.jpg" />
            </div>
          </li>
          <li>
            <div class="text">
              <div class="textTitle">
                <img src="../../assets/schemeIcon3.png" />
                <span style="color: #26BF9C">讯聘数智化药房管理系统</span>
              </div>
              <div class="textBox">
                讯聘数智化药房管理系统是在数字化药房管理系统基础上，应用智能化技术，如人工智能、大数据、物联网等技术，实现更加智能化、自动化的药房管理。该系统不仅可以管理药品的采购、库存、销售、财务等基础业务，还可以通过数据分析，预测销售趋势，提供个性化推荐服务，同时通过物联网技术，实现对药品存储环境、温度、湿度等参数的监测，确保药品质量安全。
              </div>
            </div>
            <div class="backImg">
              <img src="../../assets/scheme3.jpg" />
            </div>
          </li>
          <li class="event">
            <div class="text">
              <div class="textTitle">
                <img src="../../assets/schemeIcon4.png" />
                <span style="color: #26B6BF">讯聘智能中医辅助诊疗系统</span>
              </div>
              <div class="textBox">
                讯聘智能中医辅助诊疗系统帮助医生快速、准确地进行中医诊疗，提高诊疗效率，降低患者等待时间；提高诊疗质量，减少误诊漏诊的情况；提供丰富的中医医疗服务内容，如中药方剂推荐、针灸治疗、推拿按摩等，满足患者不同的医疗需求；帮助医疗机构优化资源配置，提高资源利用率节省医疗成本；提高患者就诊的满意度，提供更加方便快捷、准确、舒适的医疗服务。
              </div>
            </div>
            <div class="backImg">
              <img src="../../assets/scheme4.jpg" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="successfulCase" id="successfulCaseBox">
      <div class="title">成功案例</div>
      <div class="slideshow">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in list" :key="index">
            <div class="carouselBottomBox">
              <div class="imgBox"><img :src="item.img" /></div>
              <div class="textBox">
                {{ item.text }}
              </div>
              <div class="synopsisTitle">
                {{ item.introductory1 }}<span>{{ item.introductory2 }}</span>{{ item.introductory3 }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="partnerBox">
      <div class="partnerBoxImg"><img src="../../assets/partner.jpg" /></div>
      <div class="partnerBoxCentent">
        <div class="title">合作伙伴</div>
        <ul>
          <li v-for="(item, index) in icon" :key="index"><img :src="item.img"/></li>
        </ul>
      </div>
    </div>
    <div class="aboutUs" id="aboutUsBox">
      <div class="aboutUsImgBox">
        <img src="../../assets/aboutUs.jpg" />
      </div>
      <div class="aboutUsImgTextBox">
        <img src="../../assets/aboutUsText.svg" />
      </div>
    </div>
    <div class="contactUs" id="contactUsBox">
      <div class="title">联系我们</div>
      <div class="mapBox">
        <div class="contactUsBackBox"><img src="../../assets/map.png" /></div>
        <div class="textBox">
          <div class="textTitle"><img src="../../assets/sign.png" /><span>山东讯聘网络科技有限公司</span></div>
          <div class="text">咨询热线：18854161755</div>
          <div class="text">企业网址：www.xun-pin.cn</div>
          <div class="text">企业地址：山东省济南市高新区汉峪金谷A3-1栋1102室</div>
        </div>
      </div>
    </div>
    <div class="bottomNavigation">
      <div class="logo" @click="roll('homeBox')"><img src="../../assets/logoTop.png" /></div>
      <div class="navigationTop">
        <ul>
          <li class="title">解决方案</li>
          <li>讯聘数字化劳务派遣管理系统</li>
          <li>医药行业数据安全解决方案</li>
          <li>讯聘数智化药房管理系统</li>
          <li>讯聘智能中医辅助诊疗系统</li>
        </ul>
        <ul>
          <li class="title">关于我们</li>
          <li @click="roll('aboutUsBox')">公司介绍</li>
        </ul>
        <ul>
          <li class="title">联系我们</li>
          <li>咨询热线：18854161755</li>
          <li>企业网址：www.xun-pin.cn</li>
          <li>企业地址：山东省济南市高新区汉峪金谷A3-1栋1102室</li>
        </ul>
        <ul class="gzh">
          <li><img src="../../assets/gzh.png" /></li>
        </ul>
      </div>
      <div class="navigationBottom">
        <div class="bah">Copyright 2017-2023 版权所有 ©山东讯聘网络科技有限公司 |  <span class="span" @click="skip">鲁ICP备17043470号</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {img: require('../../assets/carousel1.png'), text: '使用数据信息化解决方案后，能够帮助医生快速、准确地进行中医诊疗，提高诊疗效率，缩短就诊时间，降低患者等待时间。帮助医生进行全面、系统的中医诊疗，从而提高诊疗质量，减少误诊漏诊的情况。帮助医疗机构优化资源配置，提高资源利用率节省医疗成本。',  introductory1: '来自',  introductory2: '「 中医诊疗 」',  introductory3: '企业的真实心声'},
        {img: require('../../assets/carousel2.png'), text: '在招聘过程中发现内推招聘能效更高、成本更低。平台搭建个性化内推门户，员工推荐简历后，系统会第一时间发邮件、站内通知给职位负责人，跟进面试进展。内推方案为企业带来超2万份高质量简历，内推周期由平均6小时缩短至平均1.5小时内。内推入职的员工占整体入职员工人数的比例超过了30%', introductory1: '来自',  introductory2: '「 劳务派遣 」',  introductory3: '企业的真实心声'},
        {img: require('../../assets/carousel3.png'), text: '现代医药行业涉及到大量的敏感数据，包括病人的医疗记录、处方、药品生产和销售数据等，因此数据安全问题是该行业面临的一个重要挑战。讯聘的数据安全解决方案，以数据安全需求及合法合规为动力，借鉴DSMM国标为参考，建立一套以组织为中心，涵盖管理、技术、运营三个方面的数据安全体系完美解决了这些问题。', introductory1: '来自',  introductory2: '「医药行业 」',  introductory3: '企业的真实心声'} 
      ],
      icon: [
        {img: require('../../assets/partnerIcon1.png')},
        {img: require('../../assets/partnerIcon2.png')},
        {img: require('../../assets/partnerIcon3.png')},
        {img: require('../../assets/partnerIcon4.png')},
        {img: require('../../assets/partnerIcon5.png')},
        {img: require('../../assets/partnerIcon6.png')}
      ],
      timeLineHeight: "",
    }
  },
  mounted() {
    this.roll('homeBox')
  },
  methods: {
    roll(boxName) {
      document.getElementById(boxName).scrollIntoView({ behavior: 'smooth' });
    },
    skip() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style scoped>
/* .home{overflow-y:scroll;} */
.home .navigation{ width: 100%; background: #031637;position: fixed;top: 0;left: 0;z-index: 1000;}
.home .navigationCen{ width: 1200px; margin: 0 auto; display: flex; justify-content: space-between; padding: 14px 0; align-items: center;}
.home .navigationCen .logoBox{cursor: pointer;}
.home .navigationCen ul{ margin: 0 auto; display: flex; justify-content: space-between;margin-left: 100px;}
.home .navigationCen ul li{ list-style: none; color: #ffffff; font-size: 14px; margin-right: 46px;cursor: pointer;}
.home .cententBox{width: 1200px;margin: 0 auto;background: #ffffff;position: relative;}
.home .carouselBox{ width: 100%;position: relative;height: 720px;overflow: hidden;}
.home .carouselBox img{width: 100%;height: 100%;}
.home .carouselBox .carouselText{position: absolute;z-index: 1;top: 177px;left: 0;width: 1200px;right: 0;margin: 0 auto;}
.home .carouselBox .carouselText img{width: auto;}
.home .carouselBox .relationButtonBack{position: relative;z-index: 1;top: 58px;left: 0;width: 272px;cursor: pointer;}
.home .carouselBox .relationButtonBack img{width: auto;}
.home .carouselBox .relationButtonBack span{position: absolute;color: #000F2F;font-size: 20px;top: 0;left: 0; font-weight: 700;padding: 10px 0 0 96px;}
.home .cententBox .synopsis{position: relative;z-index: 2; width: 1200px;display: flex;justify-content: space-around;box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25); padding: 40px 0;margin-top: -60px;}
.home .cententBox .synopsis span{color: #424A61;font-size: 18px;}
.home .cententBox .synopsis span em{color: #2B6AFF;font-size: 30px;font-style: normal;}
.home .cententBox .title{font-size: 35px;color: #424A61;margin: 84px 0 32px;text-align: center;}
.home .cententBox .schemeBox{padding-bottom: 119px;}
.home .cententBox .schemeBox ul li{position: relative;list-style: none; width: 100%;height: 254px;margin-top: 26px;box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);transition: all 0.6s;}
.home .cententBox .schemeBox ul li:hover{box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);transition: all 0.6s;}
.home .cententBox .schemeBox ul li .backImg{position: absolute;top: 0; left: 0;}
.home .cententBox .schemeBox ul li .text{position: absolute;top: 0; left: 0;z-index: 5;padding: 54px 0;width: 100%;}
.home .cententBox .schemeBox ul .event .text{display: flex;justify-content: right;flex-direction: column;align-items: end;}
.home .cententBox .schemeBox ul .event .text .textTitle{margin-right: 30px;}
.home .cententBox .schemeBox ul .event .text .textBox{margin-right: 30px;}
.home .cententBox .schemeBox ul li .text .textTitle{display: flex;justify-content: left;align-items: center;border-bottom: 1px solid #E5E5E5;width: 50%;padding: 0 0 25px 0;margin-left: 30px;font-size: 20px;font-weight: 700;}
.home .cententBox .schemeBox ul li .text .textTitle span{padding-left: 12px;}
.home .cententBox .schemeBox ul li .text .textBox{width: 50%;font-size: 14px;color: #878EA3;padding-top: 25px;margin-left: 30px;line-height: 22px;}

.home .successfulCase{background: #F2F7FF;width: 100%;height: 666px;}
.home .successfulCase .title{font-size: 35px;color: #424A61;padding: 120px 0 56px 0; text-align: center;}
.home .successfulCase .slideshow{width: 1200px;margin: 0 auto;}
.home .carouselBottomBox{width: 80%;margin: 0 auto;position: relative;height: 318px;overflow: hidden;}
.home .carouselBottomBox .imgBox{position: absolute;top: 0;left: 0;width: 100%;height: auto;}
.home .carouselBottomBox .imgBox img{ margin: 0 auto;position: absolute;top: 0;left: 0;width: 100%;}
.home .carouselBottomBox .textBox{position: absolute;right: 7%;top: 30%;width: 40%;font-size: 14px;color: #878EA3;}
.home .carouselBottomBox .synopsisTitle{font-size: 18px;font-weight: 700;color: #424A61;position: absolute;right: 7%;bottom: 20%;width: 40%;}
.home .carouselBottomBox .synopsisTitle span{color: #2B6AFF;}
.home .partnerBox{width: 100%;height: 325px; overflow: hidden;position: relative;}
.home .partnerBox .partnerBoxCentent{width: 1200px;height: 325px; position: absolute;top: 0;bottom: 0;left: 0;right: 0; margin: auto;display: flex;justify-content: space-around;flex-direction: column;align-items: center;}
.home .partnerBox .partnerBoxImg{width: 100%;height: 100%;display: block;position: absolute;top: 0;left: 0; }
.home .partnerBox .partnerBoxImg img{width: 100%; height: 100%;}
.home .partnerBox ul{display: flex;justify-content: center;width: 1200px;}
.home .partnerBox ul li{width: 16.6%;height: 81px;list-style: none;padding: 0 8px;}
.home .partnerBox ul li img{width: 100%;height:auto;}
.home .partnerBox .title{font-size: 35px;color: #ffffff;width: 100%; text-align: center;}
.home .aboutUs{position: relative;width: 100%;height: 800px;}
.home .aboutUs .aboutUsImgBox{width: 100%;height: 100%; font-size:0;position: absolute;top: 0;left: 0;z-index: 10;}
.home .aboutUs .aboutUsImgBox img{width: 100%;height: 100%;}
.home .aboutUs .aboutUsImgTextBox{width: 1200px;height: 398px; font-size:0;position: absolute;top: 0;left: 0;z-index: 15;right: 0;bottom: 0;margin: auto;}
.home .aboutUs .aboutUsImgTextBox img{width: 100%;height: auto;}
.home .contactUs{widows: 100%;height: 571px;position: relative;z-index: 100;background: #ffffff;}
.home .contactUs .title{font-size: 35px;color: #424A61;padding: 120px 0 56px 0; text-align: center;}
.home .contactUs .mapBox{display: flex;justify-content: space-between;border: 1px solid #E5E5E5;padding: 4px;padding-bottom: 0px; border-radius: 10px;width: 1200px;margin: 0 auto;align-items: center;}
.home .contactUs .mapBox .textBox{width: 45%;}

.home .contactUs .mapBox .textBox .textTitle{font-size: 22px;font-weight: 700;display: flex;justify-content: left;align-items: center;padding-bottom: 23px;}
.home .contactUs .mapBox .textBox .textTitle span{padding-left: 10px;}
.home .contactUs .mapBox .textBox .text{font-size: 14px; color: #424A61;padding: 5px 0;}
.home .bottomNavigation{background: #031637;padding-bottom: 10px;}
.home .bottomNavigation ul{margin-right: 100px;}
.home .bottomNavigation ul li{list-style: none;color: #818A9B;font-size: 12px;padding-bottom: 10px;cursor: pointer;transition: all 0.6s;}
.home .bottomNavigation ul li:hover{color: #ffffff;transition: all 0.6s;}
.home .bottomNavigation .navigationTop{width: 1200px;margin: 0 auto;display: flex;justify-content: left;padding: 79px 0 63px 0;border-bottom: 1px solid #818A9B;position: relative;}
.home .bottomNavigation .navigationTop ul .title{font-size: 16px;font-weight: 700;padding-bottom: 28px;color: #ffffff;}
.home .bottomNavigation .navigationTop .gzh{position: absolute;top: 82px;right: 0;margin-right: 0;}
.home .bottomNavigation .navigationBottom{width: 1200px;margin: 0 auto;font-size: 14px;font-weight: 400;color: #818A9B;margin-top: 10px;}
.home .bottomNavigation .navigationBottom img{padding-bottom: 22px;}
.home .bottomNavigation .navigationBottom .bah{font-size: 14px;color: #818A9B;text-align: center;}
.home .bottomNavigation .navigationBottom .bah span{cursor: pointer;}
.home .bottomNavigation .logo{cursor: pointer;width: 1200px;margin: 0 auto;padding-top: 65px;}
</style>